export const menuItems = [
    {
        id: 1,
        label: "menuitems.packings.text",
        icon: "bx-calendar",
        link: "/"
    },    
    {
        id: 10,
        label: "menuitems.configuration.text",
        icon: "bx-cog",
        meta: {
          authRequired: true,
          rolesRequired: "admin"
        },        
        subItems: [        
          {
            id: 9,
            label: "general.users",
            link: "/configuration/users",
            parentId: 10
          },       
          {
            id: 11,
            label: "general.general",
            link: "/configuration/organisation",
            parentId: 10
          }   
        ]
    }
];

