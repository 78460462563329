<script>
import i18n from "../i18n";
import UserConfigMixin from "@/mixins/userConfigMixin"
import AppConfig from "../app.config.json";


/**
 * Nav-bar Component
 */
export default {
  mixins: [UserConfigMixin],
  data() {
    return {
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English"
        },
        {
          flag: require("@/assets/images/flags/french.jpg"),
          language: "fr",
          title: "Français"
        }
      ],
      lan: i18n.locale,
      text: null,
      flag: null,
      value: null,
    };
  },
  components: {},
  mounted() {
    this.value = this.languages.find((x) => x.language === i18n.locale);
    this.text = this.value.title;
    this.flag = this.value.flag;
  },
  methods: {
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    setLanguage(locale, country, flag) {
      this.lan = locale;
      this.text = country;
      this.flag = flag;
      i18n.locale = locale;
      localStorage.setItem('jobsiteLocale', locale)
    },
    logoutUser() {
      this.$msal.SignOut();
    },
  },
  computed: {
    currentAccount() {
      return this.$msal.currentAccount;
    },
    portalUrl() {
      return AppConfig.app_portal;
    },
  }
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex logo-section" style="align-items:center;">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link tag="a" to="/" @click.native="$parent.$parent.$emit('reload', '/')" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/icone_wc.png" alt width="39" height="22" />
            </span>
            <span class="logo-lg">
              <img v-if="$store.state.layout.logo" :src="$store.state.layout.logo" alt height="25" />
              <img v-if="!$store.state.layout.logo" src="@/assets/images/logo_wc.png" alt height="25" />
            </span>
          </router-link>

          <router-link tag="a" to="/" @click.native="$parent.$parent.$emit('reload', '/')" class="logo logo-light">
            <span class="logo-sm" style="position: relative; left: -5px;">
              <img src="@/assets/images/icone_wc.png" alt width="39" height="22" />
            </span>
            <span class="logo-lg" style="position: relative; left: -22px;">
              <img v-if="$store.state.layout.logo" :src="$store.state.layout.logo" alt height="25" />
              <img v-if="!$store.state.layout.logo" src="@/assets/images/logo_wc.png" alt height="25" />
            </span>
          </router-link>
        </div>

        <button id="vertical-menu-btn" type="button" class="btn btn-sm px-3 font-size-16 header-item"
          @click="toggleMenu"
          v-if="currentAccount != null && this.currentAccount.idTokenClaims.extension_TrackingManagementHasAccess">
          <i class="fa fa-fw fa-bars"></i>
        </button>
      </div>

      <div class="d-flex">
        <b-dropdown class="d-inline-block d-lg-none ml-2" variant="black" menu-class="dropdown-menu-lg p-0"
          toggle-class="header-item noti-icon" right>
          <template v-slot:button-content>
            <i class="mdi mdi-magnify"></i>
          </template>

          <form class="p-3">
            <div class="form-group m-0">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username" />
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit">
                    <i class="mdi mdi-magnify"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </b-dropdown>

        <b-dropdown variant="white" right toggle-class="header-item">
          <template v-slot:button-content>
            {{ text }}
          </template>
          <b-dropdown-item class="notify-item" v-for="(entry, i) in languages" :key="`Lang${i}`" :value="entry"
            @click="setLanguage(entry.language, entry.title, entry.flag)" :class="{ active: lan === entry.language }">
            <span class="align-middle">{{ entry.title }}</span>
          </b-dropdown-item>
        </b-dropdown>

        <div class="d-lg-inline-block ms-1" v-if="currentAccount != null">
          <a :href="portalUrl" target="_blank" class="btn header-item noti-icon">
            <i class="bx bx-customize" style="margin-top:14px"></i>
          </a>
        </div>
        <div class="dropdown d-none d-lg-inline-block ml-1">
          <button type="button" class="btn header-item noti-icon" @click="initFullScreen">
            <i class="bx bx-fullscreen"></i>
          </button>
        </div>
        <div class="dropdown d-lg-inline-block ml-1" v-if="currentAccount == null">
          <button type="button" class="btn header-item noti-icon" @click="SignIn">
            <i class="bx bx-log-in font-size-16 align-middle mr-1 text-danger"></i>
            <span class="d-none d-xl-inline-block ml-1">{{ $t("general.login") }}</span>
          </button>
        </div>
        <b-dropdown v-if="currentAccount != null" right variant="black" toggle-class="header-item">
          <template v-slot:button-content style="display:flex;">
            <!-- <img
              class="rounded-circle header-profile-user"
              src="@/assets/images/users/avatar-1.jpg"
              alt="Header Avatar"
            /> -->
            <div style="display:flex; align-items:center;">
              <div class="rounded-circle header-profile-user"
                style="height:33px; width:33px; display: flex; align-items:center; justify-content:center; background-color:#F3A821;">
                <span
                  style="font-weight:600; color:white; font-size:1.1em; vertical-align:center;">{{ currentAccount.name.charAt(0) }}</span>
              </div>
              <span class="d-none d-xl-inline-block m-1">
                {{ currentAccount.name }}
              </span>
              <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
            </div>


          </template>
          <!-- item-->
          <a @click="logoutUser" class="dropdown-item text-danger" style="cursor:pointer;">
            <i class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
            {{ $t("navbar.dropdown.henry.list.logout") }}
          </a>
        </b-dropdown>
      </div>
    </div>
  </header>
</template>
