<script>
export default {
  components: {},
  props: {
  },
  computed: {
    isEnvDev() {
      return process?.env?.NODE_ENV == 'development'
    },
  }
}
</script>

<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div v-if="isEnvDev">
            {{ $msal.currentAccount.idTokenClaims.extension_OrganisationCode }}
          </div>
          <div
            class="text-sm-right d-none d-sm-block"
            style="text-align: right"
          >
            <img
              src="@/assets/images/icone_wc.png"
              alt
              width="36"
              height="20"
            />{{ $t("general.poweredby") }}
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- end footer -->
</template>
